export const Education = [{
    title:"Bachelor of Science - Computer Engineering" ,
    schoolname:"TOBB Economy And Technology University",
    graduation:"Graduation: May-2023",
    gpa:"3.1/4.0"
},{
    title:"High School Diploma" ,
    schoolname:"Ali Çağlar Anatolian High School, Afyonkarahisar",
    graduation:"Graduation: June-2018",
    gpa:"82.6/100"
}
];