export const Languages = [{
    language:"Turkish",
    level:"Native",
    score: 10,
    exam:""
},{
    language:"English",
    level:"Advance",
    score: 8,
    exam:"TOEFL PBT: 520"
},{
    language:"German",
    level:"Pre-Intermadiate",
    score: 4,
    exam:""
}
];